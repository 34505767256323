import { useRef } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { FormattedMessage } from 'react-intl';
import { formatNewLine } from '@/components/common/Intl';
import { useThrottledCallback } from 'use-debounce';
import type { Variants } from 'framer-motion';
import { motion } from 'framer-motion';
import { AimIcon } from '@/components/common/Icon';

const listVariants: Variants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      delayChildren: 0.05,
      staggerChildren: 0.5,
    },
  },
};

const itemVariants: Variants = {
  hidden: { y: 50, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

const Connect = () => {
  const imgDom1 = useRef<HTMLImageElement>();
  const imgDom2 = useRef<HTMLImageElement>();
  const { locale } = useRouter();
  const isEn = locale === 'en-US';

  const handleMouseMove = useThrottledCallback((e) => {
    const targetValue1 = imgDom1.current.getBoundingClientRect();
    const targetValue2 = imgDom2.current.getBoundingClientRect();
    const clientWidth = document.documentElement.clientWidth;
    const clientHeight = document.documentElement.clientHeight;
    imgDom1.current.style.transform = `translate3d(${
      ((e.clientX - targetValue1.left) / clientWidth) * 10
    }%,${((e.clientY - targetValue1.top) / clientHeight) * 10}%,0)`;
    imgDom2.current.style.transform = `translate3d(${
      ((e.clientX - targetValue2.left) / clientWidth) * 10
    }%,${((e.clientY - targetValue2.top) / clientHeight) * 10}%,0)`;
  }, 75);

  const handleMouseEnter = () => {
    window.addEventListener('mousemove', handleMouseMove);
  };

  const handleMouseLeave = () => {
    window.removeEventListener('mousemove', handleMouseMove);
  };

  return (
    <section
      className="relative mt-[110px] mb-[347px] flex w-screen min-w-[1200px] justify-center align-middle"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <motion.div
        className='relative z-20 w-[1200px] text-center'
        variants={listVariants}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
      >
        <motion.div
          className={`relative ${isEn ? 'text-[88px]' : 'text-[72px]'}`}
          variants={itemVariants}
          transition={{
            duration: 0.5,
          }}
        >
          <span className='relative z-20'>
            <FormattedMessage id="home.connect.title1" />
          </span>
          <Image
            src="/overseacdn/passinArk/homepage/img/layouts/star.svg"
            alt=""
            width={70}
            height={70}
            className={`absolute top-0 left-1/2 z-10 ${
              isEn ? 'translate-x-[490px]' : 'translate-x-[130px]'
            }`}
          />
        </motion.div>
        <motion.div
          className={`relative z-30 flex w-[1200px] justify-center ${
            isEn ? 'text-[60px]' : 'text-[90px]'
          }`}
          variants={itemVariants}
          transition={{
            duration: 0.5,
          }}
        >
          <Image
            ref={imgDom1}
            src={`${
              isEn
                ? '/overseacdn/passinArk/homepage/img/home/connet-picture-en1.png'
                : '/overseacdn/passinArk/homepage/img/home/connet-picture-zh1.png'
            }`}
            alt='img'
            height={150}
            width={140}
            className="duration-75 ease-linear"
          />
          <span className={`mx-[13px] ${isEn ? 'mx-[100px] mt-[10px]' : ''} z-20`}>
            <FormattedMessage id="home.connect.title2" />
          </span>
          <Image
            ref={imgDom2}
            src={`${
              isEn
                ? '/overseacdn/passinArk/homepage/img/home/connet-picture-en2.png'
                : '/overseacdn/passinArk/homepage/img/home/connet-picture-zh2.png'
            }`}
            alt='img'
            height={150}
            width={140}
            className="duration-75 ease-linear"
          />
        </motion.div>

        <motion.div
          className="mt-[60px] text-[20px] leading-10"
          variants={itemVariants}
          transition={{
            duration: 0.5,
          }}
        >
          <FormattedMessage
            id="home.connect.content"
            values={{
              newLine: formatNewLine,
            }}
          />
        </motion.div>
        <Image
          src="/overseacdn/passinArk/homepage/img/home/connect-waveline.svg"
          alt=''
          width={902}
          height={580}
          className="absolute top-[-161px] left-[131px] z-10"
        />
      </motion.div>
      <span className="absolute bottom-[-72px] left-[50%] translate-x-[450px] scale-75">
        <AimIcon />
      </span>
      {/* <div className="absolute left-[50%] top-[-500px] h-[300px]  w-[976px] -translate-x-1/2 bg-[#FF2937] opacity-30 blur-250"></div>
      <div className="absolute left-[50%] top-[-287px] h-[287px]  w-[976px] -translate-x-1/2 bg-[#0029CC] opacity-[0.45] blur-250"></div>
      <div className="absolute right-[-105px] bottom-[-100px] h-[158px] w-[692px] bg-[#FF2937] opacity-50 blur-200"></div>
      <div className="absolute right-[-105px] bottom-[-200px] h-[158px] w-[692px] bg-[#0029CC] opacity-50 blur-200"></div> */}
    </section>
  );
};

export default Connect;
