import Image from 'next/image';
import { useRouter } from 'next/router';
import { FormattedMessage } from 'react-intl';
import { formatNewLine } from '@/components/common/Intl';
import type { Variants } from 'framer-motion';
import { motion } from 'framer-motion';
import ShortCarousel from '@/components/common/ShortCarousel';
import { StarIcon, LeaderOval1, LeaderOval2, AimIcon } from '@/components/common/Icon';

const listVariants: Variants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      delayChildren: 0.5,
      staggerChildren: 0.3,
    },
  },
};

const itemVariants: Variants = {
  hidden: { y: 220, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

const leaderContnt = ['home.leader.content1', 'home.leader.content2', 'home.leader.content3'];
const Leader = () => {
  const router = useRouter();
  const { locale } = router;
  const isEn = locale === 'en-US';
  return (
    <section className="relative mt-[234px] flex w-[100vw] min-w-[1200px] justify-center align-middle">
      <motion.div
        className='relative z-10 flex w-[1200px] justify-center align-middle'
        variants={listVariants}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
      >
        <motion.div
          className={`absolute ${isEn ? 'top-[-120px]' : 'top-0'} left-0 z-20`}
          variants={itemVariants}
          transition={{
            duration: 0.5,
          }}
        >
          <span className={`absolute ${isEn ? 'left-[225px] scale-x-[1.4]' : 'left-20'} z-30`}>
            <LeaderOval1 />
          </span>
          <span className={`absolute ${isEn ? 'left-[225px] scale-x-[1.4]' : 'left-20'} z-30`}>
            <LeaderOval2 />
          </span>
          <div className="relative  text-[48px]">
            <span className="relative z-10">
              <FormattedMessage id="home.leader.title1" />
            </span>

            {/* <div className="absolute top-0 left-0  z-0 h-[118px] w-[279px] bg-[#FF2937] opacity-50 blur-100"></div> */}
          </div>
          <div className="relative z-10 text-[60px] leading-[1.25em]">
            <FormattedMessage
              id="home.leader.title2"
              values={{
                newLine: formatNewLine,
              }}
            />
          </div>
          <ShortCarousel
            content='INFLUENCER INFLUENCER'
            classnames={`${
              isEn ? 'mt-[196px]' : 'mt-[234px]'
            } w-[313px] h-[40px] ml-[50px] bg-active-color text-2xl rotate-6 `}
          />
          <span className="absolute top-[-96px] left-[-30px] scale-75">
            <AimIcon />
          </span>
          <span className="absolute bottom-[-120px] left-[50px] scale-50">
            <AimIcon />
          </span>
        </motion.div>

        <motion.div
          className='z-10 w-[660px] translate-x-[100px] pl-[200px]'
          variants={itemVariants}
          transition={{
            duration: 0.5,
          }}
        >
          <Image
            src="/overseacdn/passinArk/homepage/img/home/leader.png"
            alt='img'
            height={600}
            width={460}
          />
        </motion.div>

        <motion.div
          className={`relative z-20 ml-[50px] translate-x-[100px] ${
            isEn ? 'mt-[211px]' : 'mt-[340px]'
          }`}
          variants={itemVariants}
          transition={{
            duration: 0.5,
          }}
        >
          {leaderContnt.map((item) => (
            <div className="relative z-20 mb-[24px] w-[337px] text-justify" key={item}>
              <FormattedMessage
                id={item}
                values={{
                  newLine: formatNewLine,
                }}
              />
            </div>
          ))}
          <span className="big-zoom absolute top-[-9px] left-[-6px] z-10">
            <StarIcon />
          </span>
          <span className="small-zoom absolute top-[-26px] left-[14px] z-10">
            <StarIcon />
          </span>
          <span className="absolute top-[-203px] right-[100px] scale-50">
            <AimIcon />
          </span>
        </motion.div>
      </motion.div>

      <Image
        src="/overseacdn/passinArk/homepage/img/home/leader-waveline.svg"
        alt=''
        width={1001}
        height={827}
        className="absolute top-[-100px] right-[-150px]"
      />

      {/* <div className="absolute right-[-383px] z-0 h-[394px] w-[976px] bg-[#0029CC] opacity-40 blur-250"></div> */}
    </section>
  );
};

export default Leader;
