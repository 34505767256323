import Hls from 'hls.js';
import { useEffect, useRef } from 'react';

const Video = () => {
  const video = useRef<HTMLVideoElement>(null);

  const handleVideoPlay = () => {
    if (!video.current) return;
    video.current.muted = true;
    video.current.loop = true;
    video.current.playsInline = true;
    video.current.play();
  };

  //全屏状态变化是否展示controls
  const handleShowControl = () => {
    if (!video.current) return;
    video.current.controls = false;
    if (
      document.fullscreenElement ||
      document.mozFullscreenElement ||
      document.webkitFullscreenElement
    ) {
      video.current.controls = true;
    }
    handleVideoPlay();
    video.current.playbackRate = 1;
  };

  useEffect(() => {
    if (!video.current) return;
    const m3u8 = 'https://overseacdn.ydstatic.com/overseacdn/passinArk/video/1920p.m3u8';
    if (Hls.isSupported()) {
      const hls = new Hls();
      hls.loadSource(m3u8);
      hls.attachMedia(video.current);
      hls.on(Hls.Events.MANIFEST_PARSED, handleVideoPlay);
      //video interrupted https://stackoverflow.com/questions/36803176/how-to-prevent-the-play-request-was-interrupted-by-a-call-to-pause-error
      hls.on(Hls.Events.ERROR, function (event, data) {
        if (data.fatal) {
          switch (data.type) {
            case Hls.ErrorTypes.NETWORK_ERROR:
              // when try to recover network error
              hls.startLoad();
              break;
            case Hls.ErrorTypes.MEDIA_ERROR:
              hls.recoverMediaError();
              break;
            default:
              // when cannot recover
              hls.destroy();
              break;
          }
        }
      });
    } else if (video.current.canPlayType('application/vnd.apple.mpegurl')) {
      video.current.src = m3u8;
      video.current.addEventListener('loadedmetadata', handleVideoPlay);
    }
    document.addEventListener('fullscreenchange', handleShowControl);
    document.addEventListener('webkitfullscreenchange', handleShowControl);

    return () => {
      video.current && video.current.removeEventListener('loadedmetadata', handleVideoPlay);
      document.removeEventListener('fullscreenchange', handleShowControl);
      document.removeEventListener('webkitfullscreenchange', handleShowControl);
    };
  }, []);

  //全屏
  const handleShowVideo = async () => {
    if (!video.current) return;
    if (video.current.requestFullscreen) {
      await video.current.requestFullscreen();
    } else if (video.current.mozRequestFullscreen) {
      await video.current.mozRequestFullscreen();
    } else if (video.current.webkitRequestFullscreen) {
      await video.current.webkitRequestFullscreen();
    }
  };

  return (
    <section
      className="relative mb-[230px] h-[calc((90vw+17px)*0.5)] min-h-[495px] w-full min-w-[1200px] cursor-pointer"
      onClick={() => {
        handleShowVideo();
      }}
    >
      <video
        src=""
        poster={`${process.env.STATIC_ASSETS_URL}/overseacdn/passinArk/homepage/img/home/video.png`}
        ref={video}
        className="w-[90vw] min-w-[1080px]"
        style={{ marginLeft: 'calc((100vw - 90vw - 17px) / 2)' }}
      />
      <div className="absolute left-[500px] bottom-[-250px] h-[225px] w-[976px] -translate-x-1/2 bg-[#FF2937] opacity-30 blur-250"></div>
      <div className="absolute left-[500px] bottom-[-450px] h-[208px] w-[976px] -translate-x-1/2 bg-[#0033FF] opacity-40 blur-250"></div>
    </section>
  );
};

export default Video;
