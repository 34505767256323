import type { NextPage } from 'next';
import Video from '@/components/pages/home/Video';
import Carousel from '@/components/common/Carousel';
import Leader from '@/components/pages/home/Leader';
import Data from '@/components/pages/home/Data';
import Connect from '@/components/pages/home/Connect';
import Case from '@/components/pages/home/Case';
import Partner from '@/components/pages/home/Partner';
import useGAPageView from '@/hooks/useGA';

const IndexPage: NextPage = () => {
  useGAPageView({ name: 'passionArk_homepage' });

  return (
    <section className='w-screen min-w-[1200px] overflow-x-hidden'>
      <Video />
      <Carousel
        str="GLOBAL "
        stokeStr="LEADER "
        classnames={'text-[40px] h-[80px]'}
        fontSize={40}
      />
      <Leader />
      <Data />
      <Carousel
        str=""
        stokeStr="CONNECT "
        hasBg={false}
        classnames={'rotate-[-3deg] h-[150px] text-[100px]'}
        fontSize={100}
      />
      <Connect />
      <Case />
      <Carousel str="PARTNER " stokeStr="OUR " classnames={'text-[40px] h-[80px] '} fontSize={40} />
      <Partner />
    </section>
  );
};

export default IndexPage;
