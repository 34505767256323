import { useInView } from 'framer-motion';
import { useEffect, useRef } from 'react';

interface AutoIncNumberProps {
  interval?: number;
  value: number;
}

const AutoIncNumber: React.FC<AutoIncNumberProps> = ({ value, interval = 80 }) => {
  const ref = useRef<HTMLSpanElement>(null);
  const isInView = useInView(ref, { once: true });

  let fixedValue = 0;
  const valueDotArr = value.toString().split('.');
  if (valueDotArr.length === 2) {
    fixedValue = valueDotArr.pop().length;
  }

  useEffect(() => {
    if (isInView) {
      let done = false;
      let _value = 0; // 变化的value总和，用于最终值比较
      const stepValue = value === 1 ? 1 : Math.max(value / interval, 0.01);
      const step = () => {
        if (_value + stepValue >= value) {
          _value = value;
          done = true;
        } else {
          _value += stepValue;
        }
        if (ref.current) ref.current.innerHTML = _value.toFixed(fixedValue);

        if (!done) {
          window.requestAnimationFrame(step);
        }
      };
      window.requestAnimationFrame(step);
    }
  }, [isInView, interval, value, fixedValue]);

  return <span ref={ref} className="font-semibold"></span>;
};

export default AutoIncNumber;
