import { useRouter } from 'next/router';
import Image from 'next/image';
import type { Variants } from 'framer-motion';
import { motion } from 'framer-motion';
import { StarIcon } from '@/components/common/Icon';
import { FormattedMessage } from 'react-intl';
import { AimIcon } from '@/components/common/Icon';

const brandImg = ['partner-logo1', 'partner-logo2', 'partner-logo3', 'partner-logo4'];

const listVariants: Variants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      delayChildren: 0.05,
      staggerChildren: 0.5,
    },
  },
};

const itemVariants: Variants = {
  hidden: { y: 50, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};
const imagePrefix = process.env.STATIC_ASSETS_URL || '';
const Partner = () => {
  const { locale } = useRouter();
  const isEn = locale === 'en-US';

  return (
    <motion.section
      className="relative mt-[193px] mb-[305px] w-screen min-w-[1200px]"
      variants={listVariants}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
    >
      <motion.div
        className="relative z-30 mx-auto text-center"
        variants={itemVariants}
        custom={1}
        transition={{
          duration: 0.5,
        }}
      >
        <div className={`${isEn ? 'text-[88px]' : 'text-[52px]'}`}>
          <span className='relative'>
            <FormattedMessage id='home.partner.title1' />
            <span className="big-zoom absolute top-[10px] left-[-40px] ">
              <StarIcon />
            </span>
            <span className="small-zoom absolute top-[-10px] left-[-60px] ">
              <StarIcon />
            </span>
          </span>
        </div>
        <div className={`${isEn ? 'text-[60px]' : 'text-[65px]'} mb-[50px] font-thin`}>
          <span className='relative'>
            <FormattedMessage id='home.partner.title2' />
            <span
              className={`absolute z-30 ${
                isEn ? 'top-[22px] right-[-313px]' : 'top-[-92px] right-[10px]'
              } inline-block h-[45px] w-[188px] rotate-[-5deg] bg-auto text-[30px]`}
              style={{
                backgroundImage: `url(${imagePrefix}/overseacdn/passinArk/homepage/img/home/partner-trust-bg.svg)`,
              }}
            >
              <span className='inline-block w-[160px] overflow-hidden'>
                <span className='short-carousel-front'>{'TRUST TRUST TRUST TRUST '}</span>
                <span className='short-carousel-back'>{'TRUST TRUST TRUST TRUST '}</span>
              </span>
            </span>
          </span>
        </div>
      </motion.div>
      <motion.div
        className="relative z-30 w-screen min-w-[1200px] overflow-hidden"
        variants={itemVariants}
        custom={2}
        transition={{
          duration: 0.5,
        }}
      >
        {brandImg.map((item, index) => (
          <div key={item} className="h-[80px] text-[80px]">
            <span
              className={`${
                index % 2 === 0 ? 'carousel-front' : 'carousel-reverse-front'
              } w-[max-content]`}
              style={{ animationDuration: '80s' }}
            >
              <Image
                src={`/overseacdn/passinArk/homepage/img/home/${item}.svg`}
                alt='img'
                width={3000}
                height={60}
                className="inline-block"
              />
              <Image
                src={`/overseacdn/passinArk/homepage/img/home/${item}.svg`}
                alt='img'
                width={3000}
                height={60}
                className="inline-block"
              />
            </span>
            <span
              className={`${
                index % 2 === 0 ? 'carousel-back' : 'carousel-reverse-back'
              }  w-[max-content]`}
              style={{ animationDuration: '80s' }}
            >
              <Image
                src={`/overseacdn/passinArk/homepage/img/home/${item}.svg`}
                alt='img'
                width={3000}
                height={60}
                className="inline-block"
              />
              <Image
                src={`/overseacdn/passinArk/homepage/img/home/${item}.svg`}
                alt='img'
                width={3000}
                height={60}
                className="inline-block"
              />
            </span>
          </div>
        ))}
      </motion.div>
      {/* <div className="z-0 absolute -right-[713px] -top-[280px] w-[1128px] h-[573px] bg-[#0029CC] opacity-[0.35] blur-250"></div>
      <div className="z-0 absolute -left-[128px] -bottom-[264px] w-[838px] h-[407px] bg-[#0029CC] opacity-30 blur-250"></div> */}
      <span className="absolute top-[-50px] right-[50%] translate-x-[480px] scale-75">
        <AimIcon />
      </span>
    </motion.section>
  );
};

export default Partner;
