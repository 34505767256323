import { useRouter } from 'next/router';
import { FormattedMessage } from 'react-intl';
import AutoIncNumber from '@/components/common/AutoIncNumber';
import type { Variants } from 'framer-motion';
import { motion } from 'framer-motion';
import {
  DataCircleEn1,
  DataCircleEn2,
  DataCircleEn3,
  DataCircleEn4,
  DataCircleZh1,
  DataCircleZh2,
  DataCircleZh3,
  DataCircleZh4,
  YoutubeIcon2,
  InstagramIcon2,
  TwitterIcon2,
  TikTokIcon2,
  AimIcon,
} from '@/components/common/Icon';

const listVariants: Variants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      delayChildren: 0.05,
      staggerChildren: 0.4,
    },
  },
};

const itemVariants: Variants = {
  hidden: { y: 50, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

const platforms = [YoutubeIcon2(), InstagramIcon2(), TwitterIcon2(), TikTokIcon2()];
const Data = () => {
  const router = useRouter();
  const { locale } = router;
  const isEn = locale === 'en-US';
  const dataArr = [
    {
      key: 'number',
      value: 260,
      unit: 'home.data.unit1',
      circle: isEn ? (
        <DataCircleEn1 className={'circle-around inline-block'} />
      ) : (
        <DataCircleZh1 className={'circle-around inline-block'} />
      ),
    },
    {
      key: 'number',
      value: isEn ? 201.2 : 2.012,
      unit: 'home.data.unit2',
      circle: isEn ? (
        <DataCircleEn2 className={'circle-around inline-block'} />
      ) : (
        <DataCircleZh2 className={'circle-around inline-block'} />
      ),
    },
    {
      key: 'number',
      value: isEn ? 4.58 : 45.8,
      unit: 'home.data.unit3',
      circle: isEn ? (
        <DataCircleEn3 className={'circle-around inline-block'} />
      ) : (
        <DataCircleZh3 className={'circle-around inline-block'} />
      ),
    },
    {
      key: 'platform',
      circle: isEn ? (
        <DataCircleEn4 className={'circle-around inline-block'} />
      ) : (
        <DataCircleZh4 className={'circle-around inline-block'} />
      ),
    },
  ];
  return (
    <section className="relative mt-[160px] mb-[280px] flex w-screen min-w-[1200px] justify-center align-middle">
      <motion.div
        className='z-20 flex w-[1200px] justify-around'
        variants={listVariants}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
      >
        {dataArr.map((item, index) => (
          <motion.span
            className='relative'
            key={index}
            variants={itemVariants}
            transition={{
              duration: 0.5,
            }}
          >
            <span className='circle-container relative z-30 inline-block'>{item.circle}</span>
            {item.key === 'number' ? (
              <span className="absolute top-[66px] left-0 z-20 inline-block w-[214px] text-center text-[46px] leading-[1.4] text-active-color">
                <AutoIncNumber value={item.value} />
                <div className={`text-[14px]`}>
                  <FormattedMessage id={item.unit} />
                </div>
              </span>
            ) : (
              <span className='absolute top-[85px] left-0 z-20 inline-block w-[214px] text-center'>
                {platforms.map((item, platformsIndex) => (
                  <span className="inline-block" key={platformsIndex}>
                    {item}
                  </span>
                ))}
              </span>
            )}
          </motion.span>
        ))}
      </motion.div>
      <span className="absolute bottom-[-118px] left-[50%] translate-x-[500px] scale-50">
        <AimIcon />
      </span>
      <span className="absolute bottom-[-62px] left-[50%] translate-x-[-270px] scale-75">
        <AimIcon />
      </span>
      <div className="absolute right-[-383px] h-[308px] w-[976px] bg-[#0029CC] opacity-40 blur-250"></div>
      {/* <div className="absolute left-[-117px] top-[-215px] h-[292px] w-[868px] bg-[#FF2937] opacity-30 blur-250"></div> */}
    </section>
  );
};

export default Data;
